import { useRouter, useRoute } from "vue-router";

import Swal from "@/plugins/swal";
import * as api from "@/plugins/api";

export async function init(query) {
  if (query.type === "accept") {
    await accept(query.team, query.token);
  } else {
    await decline(query.team, query.token);
  }
}

async function accept(team, token) {
  const router = useRouter();
  const route = useRoute();
  try {
    const response = await api.fetch({
      url: `/teams/invite/${team}/${token}/info`,
      resource: "v1",
      method: "GET"
    });

    // Checking invite user existing
    if (response.user_exists) {
      try {
        await api.fetch({
          url: `/teams/invite/${team}/${token}/accept`,
          resource: "v1",
          method: "PUT"
        });

        Swal.fire({
          title: "Success!",
          text: "You have successfully joined the team!",
          icon: "success",
          confirmButtonText: "Cool"
        });

        await router.push({
          name: "dashboard.team.show",
          params: { id: team }
        });
      } catch (e) {
        Swal.fire({
          title: "Warning!",
          text: "To accept the invitation, sign in and reopen the link!",
          icon: "warning",
          confirmButtonText: "Cool"
        });

        await router.push({
          name: "Login"
        });
      }
    } else {
      await router.push({
        name: "Register",
        query: {
          service: "invite",
          ...route.query
        }
      });
    }
  } catch (e) {
    console.log(e);
    Swal.fire({
      title: "404!",
      text: "404!",
      icon: "warning",
      confirmButtonText: "Cool"
    });

    throw e;
  }
}

async function decline(team, token) {
  try {
    return await api.fetch({
      url: `/teams/${team}/employee/invite/${token}/decline`,
      resource: "v1",
      method: "DELETE"
    });
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export function serviceInvite() {
  return {
    init
  };
}
