import { defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import { serviceInvite } from "./invite.service.js";

export default defineComponent({
  name: "RouteServiceProvider",
  setup() {
    const route = useRoute();
    const state = reactive({
      services: [
        {
          name: "invite",
          service: serviceInvite()
        }
      ]
    });

    const currentService = state.services.find(
      s => s.name === route.params.service
    );
    currentService.service.init(route.query);
  }
});